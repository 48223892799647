body {
    background-color:#333;
    margin:0;
}
canvas {
    padding:0;
    width: 100%;
    height:100%;
    position:absolute;
}
html,body {
    margin:0;
    padding:0;
    width: 100%;
    height:100%;
    overflow: hidden;
}
.option-bar {
    width: calc(100% - 3px);
    background:#333;
    display: block;
    position:absolute;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    left: 1.5px;
}
#text {
    width: calc(100% - 1px);
    padding:0;
    color: white;
    line-height: 20px;
    margin:0;
    border:none;
    background-color: rgba(0, 0, 0, 0);
}
#text:focus {
    outline: white auto 5px;
    border:none;
}
[contentEditable=true]:empty:before {
    content:attr(data-ph);
    color: white;
}